import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Breadcrumbs } from '../responsive-kontext-info-header/responsive-kontext-info-header.component';

export interface Url {
  name: string;
  path: string;
  queryParams: Params;
}

@Injectable()
export class HistoryService {
  // Subject für die aktuelle Back-URL
  private backUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // Stack von möglichen Back-URLs
  private stack: string[] = [];

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e: NavigationEnd) => e.url)
      )
      .subscribe((nextUrl) => {
        const currentUrl = this.stack[this.stack.length - 1];
        const previousUrl = this.stack[this.stack.length - 2];

        const nextPath = nextUrl?.split('?')[0];
        const currentPath = currentUrl?.split('?')[0];

        if (nextUrl === previousUrl) {
          // Wenn die Urls der vorherigen under der nächsten Seite gleich sind,
          // so handelt es sich um eine Zurück-Navigation und
          // wir löschen die aktuelle und vorherige Seite aus dem History-Stack.
          this.stack.pop();
          this.stack.pop();
        } else if (nextPath === currentPath) {
          // Wenn die Pfade der jetzigen under der nächsten Seite gleich sind,
          // ersetzen wir die vorherige URL mit den geänderten Parametern.
          this.stack.pop();
        }

        // backURL bekannt geben
        this.backUrl.next(this.stack[this.stack.length - 1]);

        // Jetzige URL dem Stack hinzufügen
        this.stack.push(nextUrl);
      });
  }

  public breadcrumbs$: Observable<Breadcrumbs> = this.backUrl.pipe(
    map((url) => ({
      parents: HistoryService.createBreadcrumb(url)
    }))
  );

  private static createBreadcrumb(backUrl: string | undefined): Url[] {
    let linkText;
    let parentBreadcrumbs: Url[] = [{ name: 'Startseite', queryParams: [], path: '' }];
    let [path, param] = (backUrl ?? '').split('?');

    if (path === '/studienfelder') {
      linkText = ' zur Studienfeldauswahl';
    } else if (path === '/studienfeldinfo') {
      linkText = ' zur Studienfeldinfo';
    } else if (path?.includes('studienangebot')) {
      linkText = ' zum Studienangebot';
    } else if (
      path.includes('suche') &&
      (param?.includes('sfe=') || param?.includes('sfa=') || param?.includes('sw='))
    ) {
      linkText = ' zur Ergebnisliste';
    }
    if (linkText) {
      // Konvertiere String in Params-Objekt
      const queryParams = Object.fromEntries(new URLSearchParams(param));
      parentBreadcrumbs.push({ name: `zurück ${linkText}`, queryParams, path: path });
    }
    return parentBreadcrumbs;
  }

  public addManuallyToHistory(url: string | undefined) {
    if (url) {
      this.stack.push(url);
      this.backUrl.next(this.stack[this.stack.length - 1]);
    }
  }
}
